import * as Sentry from "@sentry/svelte";
import { CaptureConsole } from '@sentry/integrations';
import type { Integration} from "@sentry/types";
import { BrowserTracing } from "@sentry/tracing";
import { backend } from '$lib/environment-config'

import { browser } from '$app/environment'
import '$lib/i18n' // Import to initialize. Important :)
import { locale, waitLocale } from 'svelte-i18n'

try {
  // no localhost or similar
  if (!window.location.href.startsWith('http://') && localStorage.getItem("no-tracking") !== "true" && !(window.location.href.indexOf('beta.') > -1)) {
    let integrations: Integration[] = [new BrowserTracing(), new CaptureConsole({
      levels: ['error']
    })]

    // Initialize the Sentry SDK here
    Sentry.init({
      dsn: "https://35a281fce3794ea094b0130bf1455cbe@o4504835865378816.ingest.sentry.io/4504835868393472",
      tunnel: backend + '/status',
      integrations: integrations
    });
  }
} catch(e) {}

if (browser) {
  locale.set(window.navigator.language)
}
